import * as React from 'react'
import { graphql } from 'gatsby'
import {
  withPrismicUnpublishedPreview,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'

import linkResolver from 'utils/linkresolver'

import PageTemplate from 'templates/page'

const NotFoundPage = ({ data }) => {
  return (
    <div>
      <h1>404</h1>
    </div>
  )
}

export default withPrismicUnpublishedPreview(NotFoundPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_SITE_REPO,
    linkResolver,
    componentResolver: componentResolverFromMap({
      page: PageTemplate,
    }),
  },
])

export const query = graphql`
  query NotFoundPage {
    prismicPage(uid: {eq: "404"}){
      _previewable
    }
  }`